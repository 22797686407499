export const isMobile = function() {
  const isMobile = /Android|iPhone|iPad|iPod|ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/i.test(navigator.userAgent);
  return isMobile;
};

export const isWeiXin = () => {
  console.log(navigator.userAgent.toLowerCase());
  if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
    return true;
  } else {
    return false;
  }
};

export const isIOS = function() {
  //iPhone|iPad|iPod|iOS
  const isMobile = /iPhone|iPad|iPod|iOS/i.test(navigator.userAgent);
  return isMobile;
};
