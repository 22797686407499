// import { Notify } from "vant";
import ajax from "./ajax.js";
// import router from "@/router";

let base = process.env.VUE_APP_BASE_URL;

const getFiles = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/get?id=${id}`);
};

const shareflexqrcode = () => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/share-flex-qrcode`);
};

const previewPdf = (cosKey) => {
  return ajax.get(`${base}/api/itedu/v1/pdf/preview?cosKey=${cosKey}`);
};

export const userCosApi = {
  // import时得花括号明确
  getFiles: getFiles,
  shareflexqrcode: shareflexqrcode,
  previewPdf: previewPdf,
};
