import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const get = (id) => {
  //api/itedu/v1/cs-config/get-match
  return ajax.get(`${base}/api/itedu/v1/cs-config/get-match?id=${id}`);
};

export const customerServiceApi = {
  // import时得花括号明确
  get: get,
};
