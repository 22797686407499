<template>
  <div class="login">
    <div class="empty" @click="onCloseCsWidget"></div>
    <div class="widget">
      <div class="row">
        <div class="message">
          {{ "请扫描下方二维码哦～" }}
        </div>
      </div>
      <div class="row">
        <div class="img">
          <img :src="csMatch.url" alt="" />
        </div>
      </div>
      <div class="row">
        <div class="cs-name"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { customerServiceApi } from "@/api/customerServiceApi.js";
export default {
  props: {
    csId: String, // 客服:1000, 助教: 1001, payDone: 1002, 出错: 1003
  },
  data() {
    return {
      csMatch: {
        match: {
          id: 0,
          employee: "",
          url: "",
        },
        url: "",
      },
      //customserviceImg: require('@/assets/images/custom-service.png'),
    };
  },
  methods: {
    onCloseCsWidget() {
      this.$EventBus.$emit("onCloseCsWidget", true);
    },
    getBanner() {
      customerServiceApi.get(this.csId).then((ret) => {
        console.log("cs", ret);
        if (ret.code == 0 && ret.data) {
          this.csMatch = ret.data;
        }
      });
    },
  },
  mounted() {
    console.log("cs mounted", this.csId);
    this.getBanner();
  },
};
</script>

<style lang="less" scoped>
.login {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  .empty {
    flex: 1;
  }
  .widget {
    border-radius: 24px 24px 0px 0px;
    box-shadow: 4px 2px 10px 4px rgba(0, 0, 0, 0.5);
    clip-path: inset(-24px 0px 0px 0px);
    background: #ffffff;
    height: 480px;
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #000000;
      letter-spacing: 3.92px;
      font-weight: 500;
      .message {
        margin: 40px 0 60px 0;
      }
      img {
        width: 170px;
        height: 170px;
      }
      .cs-name {
        margin: 10px 0 0 0;
      }
    }
  }
}
</style>
