// 除去日期月份前的0
export function dislodgeZero(str) {
  let strArray = str.split("-");
  strArray = strArray.map(function(val) {
    if (val[0] == "0") {
      return (val = val.slice(1));
    } else {
      return val;
    }
  });
  return strArray.join("-");
}

// 获取当前时间
export function thisTime() {
  //当前日期
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var mytime = date.toLocaleTimeString(); //获取当前时间
  // myDate.toLocaleString( );
  var time = year + "-" + month + "-" + day + " " + mytime;
  return time;
}

// 获取URL指定参数
export function getUrlStr(name) {
  const reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
  if (reg.test(window.location.href)) {
    return decodeURIComponent(RegExp.$2.replace(/\+/g, " "));
  }
  return undefined;
}

export function setDate(date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d;
}
//本周第一天最后一天
export function showWeekFirstDay() {
  var Nowdate = new Date();
  var day = Nowdate.getDay();
  if (day === 0) {
    day = 7;
  }
  var WeekFirstDay = new Date(Nowdate - (day - 1) * 86400000);
  return WeekFirstDay;
}
export function showWeekLastDay() {
  var Nowdate = new Date();
  var day = Nowdate.getDay();
  if (day === 0) {
    day = 7;
  }
  var WeekFirstDay = new Date(Nowdate - (day - 1) * 86400000);
  var WeekLastDay = new Date((WeekFirstDay / 1000 + 6 * 86400) * 1000);
  return WeekLastDay;
}

export function getTime(day) {
  let re = /(\d{4})(?:-(\d{1,2})(?:-(\d{1,2}))?)?(?:\s+(\d{1,2}):(\d{1,2}):(\d{1,2}))?/.exec(day);
  return new Date(re[1], (re[2] || 1) - 1, re[3] || 1, re[4] || 0, re[5] || 0, re[6] || 0).getTime();
}

export function getDay(day) {
  var today = new Date();

  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

  today.setTime(targetday_milliseconds); //注意，这行是关键代码

  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
}

export function doHandleMonth(month) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
}

/**
 * 根据文件名后缀区分 文件类型
 *
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
export function matchFileType(fileName) {
  // 后缀获取
  let suffix = "";
  // 获取类型结果
  let result = "";
  if (!fileName) return false;
  try {
    // 截取文件后缀
    suffix = fileName.substr(fileName.lastIndexOf(".") + 1, fileName.length);
    // 文件后缀转小写，方便匹配
    suffix = suffix.toLowerCase();
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }

  let fileTypeList = [
    // 图片类型
    { typeName: "image", types: ["png", "jpg", "jpeg", "bmp", "gif"] },
    // 文本类型
    { typeName: "txt", types: ["txt"] },
    // excel类型
    { typeName: "excel", types: ["xls", "xlsx"] },
    { typeName: "word", types: ["doc", "docx"] },
    { typeName: "pdf", types: ["pdf"] },
    { typeName: "ppt", types: ["ppt"] },
    // 视频类型
    { typeName: "video", types: ["mp4", "m2v", "mkv", "avi", "wmv", "mpg", "mpeg", "mov", "flv"] },
    // 音频
    { typeName: "radio", types: ["mp3", "wav", "wmv"] },
  ];
  // let fileTypeList = ['image', 'txt', 'excel', 'word', 'pdf', 'video', 'radio']
  for (let i = 0; i < fileTypeList.length; i++) {
    const fileTypeItem = fileTypeList[i];
    const typeName = fileTypeItem.typeName;
    const types = fileTypeItem.types;
    // console.log(fileTypeItem);
    result = types.some(function(item) {
      return item === suffix;
    });
    if (result) {
      return typeName;
    }
  }
  return "other";
}
