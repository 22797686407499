<template>
  <div class="container">
    <div class="app-main">
      <div class="header">
        <div class="left" @click="goHome()">
          <img :src="goHomeImg" />
        </div>
        <div class="middle">{{ title }}</div>
        <div class="right">
          <div class="img-share"></div>
        </div>
      </div>
      <div class="panel">
        <div class="diskNav">
          <ul class="ul">
            <li class="li" v-for="(item, index) in backNav" :key="index" @click="goNavBack(index)">
              <span>
                <span class="pl10">/</span>
                {{ item }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="disk">
        <ul class="diskList">
          <div class="clearfix" v-for="(item, index) in filesChildren" :key="index" @click="gotoNext(item)">
            <div class="icon" v-if="item.itemType === 0">
              <img :src="fileImg" />
            </div>
            <div class="icon" v-if="item.itemType === 1">
              <img :src="taskImg" />
            </div>
            <div class="topTitle">{{ item.title }}</div>
          </div>
        </ul>
      </div>
      <div class="pdf-container" v-if="isPdf">
        <div class="header">
          <van-icon name="close" color="#fff" size="30" @click="isPdf = false" />
        </div>
        <div class="pdf-body">
          <img v-for="(item, index) in previewUrlList" :key="index" :src="item" />
        </div>
        <!-- <iframe :src="src" width="100%" height="100%" frameborder="0"></iframe> -->
      </div>
      <div class="download" v-if="filesChildren && filesChildren.length > 0">
        <div class="btn" @click="getZip()">
          <div>打包下载</div>
        </div>
      </div>
      <van-popup v-model="isWeixinPop" round position="bottom" :style="{ height: '35%' }">
        <div class="wx-download-label">
          已复制链接，到浏览器粘贴打开:
          <textarea v-model="wxZipUrl" id="copy-textarea" />
        </div>
      </van-popup>

      <csWidget v-if="weChatShow" :csId="'1'"></csWidget>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
// import pdf from "vue-pdf";
import { Dialog, Notify, Popup } from "vant";
import { userCosApi } from "@/api/userCosApi.js";
import { getUrlStr, matchFileType } from "@/util/validate";
import csWidget from "@/components/csWidget.vue";
import { isMobile, isWeiXin } from "@/util/browser.js";

export default {
  components: {
    // pdf,
    csWidget,
  },
  data() {
    return {
      //isDownload: true,
      isPdf: false,
      src: "",
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      scale: 100, //  开始的时候默认和容器一样大即宽高都是100%
      //pageRotate: 0,  // 旋转角度
      fileImg: require("@/assets/images/disk/file.png"),
      taskImg: require("@/assets/images/disk/task.png"),
      goShareImg: require("@/assets/images/disk/share.png"),
      goHomeImg: require("@/assets/images/disk/home.png"),
      weChatShow: false, //弹框
      id: "", //页面id
      title: "", //页面标题
      zipUrl: "", //打包下载
      weChat: {}, //分享
      filesChildren: [],
      backNav: [],
      navDct: {},

      isWeixinPop: false,
      isWeixinMode: false,
      wxZipUrl: "",

      previewUrlList: [],
    };
  },
  methods: {
    close() {
      this.isPdf = false;
    },
    goNavBack(index) {
      if (index < 0 || index > this.backNav.length) {
        return;
      }
      this.backNav = this.backNav.slice(0, index + 1);
      console.log(this.backNav);
      this.isPdf = false;
      let id = this.backNav.join("/");
      this.filesChildren = this.navDct[id].children;
    },
    // 下级目录
    async gotoNext(item) {
      console.log("click item", item);
      this.isPdf = false;
      if (item.itemType == 0) {
        this.backNav = item.id.split("/");
        this.navDct[item.id] = item;

        if (item.children !== undefined && item.children.length > 0) {
          this.filesChildren = item.children;
        }
      } else if (item.itemType == 1) {
        this.previewUrlList = [];
        // this.filesChildren = item.children
        if (matchFileType(item.title) == "image") {
          const imgList = [];
          imgList.push(item.url);
          ImagePreview({
            images: imgList, //需要预览的图片 URL 数组
            closeable: true,
          });
        } else if (matchFileType(item.title) == "pdf") {
          this.isPdf = true;
          this.src = item.url;
          userCosApi.previewPdf(item.cosKey).then((ret) => {
            console.log("preview", ret);
            if (ret.code == 0) {
              this.previewUrlList = ret.datas;
            }
          });
          // this.src = item.url;
          // this.loadPdfHandler();
          // Notify({
          //   type: "warning",
          //   message: "该文件类型不支持预览",
          // });
        } else {
          Notify({
            type: "warning",
            message: "该文件类型不支持预览",
          });
        }
      }
    },
    //获取列表
    getList() {
      this.backNav = [];
      this.cacheArr = [];
      // this.isDownload = false
      userCosApi.getFiles(this.id).then((res) => {
        let { code, data, msg } = res;
        console.log("..get list..", data);
        if (code == 0 && data) {
          let files = res.data.files;
          let item = files[0];
          this.filesChildren = item.children;
          this.backNav.push(item.id);
          this.navDct[item.id] = item;
          this.title = res.data.title;
          this.zipUrl = "http:" + res.data.zipUrl;
          // console.log(this.backNav, '导航列表')
        } else {
          Notify({
            type: "warning",
            message: msg,
          });
          this.weChatShow = true;
        }
      });
    },
    // 返回主页
    goHome() {
      this.$router.push("/");
    },
    // 分享后获取各位码信息
    getShareFlexQrcode() {
      userCosApi.shareflexqrcode().then((res) => {
        if (res.code == 0) {
          this.weChat = res.data.weChat;
        }
      });
    },
    //分享
    share() {
      console.log("分享");
      this.show = true;
      this.getShareFlexQrcode();
    },
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
      this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
      this.$refs.pdf.$el.style.height = parseInt(this.scale) + "%";
    },
    //  上一页
    prev() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // 下一页
    next() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    // 打包下载
    getZip() {
      let url = this.zipUrl;
      if (url.startsWith("https://")) {
        url = this.zipUrl;
      } else if (url.startsWith("http://")) {
        url = url.replaceAll("http://", "https://");
      } else {
        url = "https://" + url;
      }
      if (this.isMobileMode) {
        this.isWeixinPop = true;
        this.wxZipUrl = url;
        this.$nextTick(() => {
          const text = document.getElementById("copy-textarea");
          console.log("...", text);
          text.select();
          if (document.execCommand("copy")) {
            document.execCommand("copy");
            Notify({
              type: "success",
              message: "已复制:" + this.wxZipUrl,
            });
          }
        });
      } else {
        window.open(url, "_blank");
      }
    },
  },
  mounted() {
    // console.log(Object.prototype.toString.call(typeof null));
    this.id = getUrlStr("id");
    console.log(this.id);
    this.getList();
    this.isMobileMode = isWeiXin();
  },
  destroyed() {},
  filters: {},
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .app-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .header {
      background: #fff;
      height: 62px;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.4px;
      line-height: 62px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 40px;
        margin: 0 0 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0;
        img {
          height: 40px;
          width: 40px;
        }
      }
      .middle {
        width: 270px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right {
        width: 40px;
      }
    }
    .panel {
      margin: 10px 15px;
      width: 344px;
      border-radius: 8px;
      background: #fff;
      /*网盘*/
      .diskNav {
        font-size: 14px;
        color: #979797;
        line-height: 34px;
        padding: 0px 14px;
        font-weight: 500;
        .ul {
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          overflow: auto;
          white-space: nowrap;
          position: relative;
          box-sizing: border-box;
          .li {
            width: auto;
            height: 100%;
            margin-right: 2px;
            display: inline-block;
            position: relative;
          }
        }
        // 隐藏滚动条
        .ul::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .disk {
      width: 344px;
      border-radius: 8px;
      background: #fff;
      flex: 1;
      overflow-y: scroll;
      .diskList {
        .clearfix {
          color: #505051;
          font-size: 13px;
          border-bottom: #f1f1f1 solid 1px;
          height: 36px;

          display: flex;
          flex-direction: row;
          .icon {
            margin: 8px 14px;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .topTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .download {
      position: fixed;
      left: 249px;
      bottom: 40px;
      z-index: 2;
      .btn {
        width: 110px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #4d52d1;
        border-radius: 14px;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
    .pdf-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 3;
      background: rgba(0, 0, 0, 0.8);
      .header {
        height: 40px;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: row-reverse;
        .van-icon {
          margin: 5px;
        }
      }
      .pdf-body {
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 90%;
          margin: 0px 5px;
        }
      }
    }
    .wx-download-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px;
      font-size: 14px;
      font-weight: 500;
      #copy-textarea {
        margin: 20px 0px 0px 0px;
        width: 90%;
        height: 100px;
        border: none;
      }
    }
  }
}
</style>
